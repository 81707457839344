<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link
            :to="{ name: 'bidbondtemplates'}">Bid Bond Templates
        </router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">View</a></li>
    </ol>
    <h4 class="page-header"> Bid Bond Template</h4>
    <div class="panel panel-inverse">
      <div class="panel-body">
        <div class="text-right">
          <router-link
              :to="{ name: 'bidbondtemplates.edit', params: { id: $route.params.id }}"
              class="btn btn-primary" v-if="this.$can.view($store.getters.getAuthUser, 'update-bidbond-templates')"
          >
            <i class="fa fa-edit"></i> Edit
          </router-link>
          <button
              v-if="this.$can.view($store.getters.getAuthUser, 'delete-bidbond-templates')"
              class="btn btn-danger ml-1" type="button"
              @click="deleteTemplate"
          ><i class="fa fa-trash"></i> Delete</button>
        </div>
        <hr>
        <div v-html="template" />
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      template: {}
    }
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/bid-bond-templates/' + this.$route.params.id).then(response => {
        this.template = response.data;
      });
    },

    deleteTemplate() {
      this.$axios.delete('/api/v1/bid-bond-templates/' + this.$route.params.id).then(() => {
        this.$router.push({name: 'bidbondtemplates'});
      })
    }
  }
}
</script>

